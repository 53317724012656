<template>
  <div>
    <p style='margin-bottom: 20px'>Select the <strong>Line of businesses</strong> for this quote.
      You can select multiple at once.</p>

    <v-tabs v-model='policyTypesCategory'>
      <v-tab key='personal'>Personal</v-tab>
      <!--<v-tab key="commercial">Commercial</v-tab>-->
    </v-tabs>

    <template v-if='displayedPolicyTypes.length > 0'>

      <div class='mt-5'>
        <div class='d-flex' v-for='type in displayedPolicyTypes'>
          <div class='flex-grow-1'>
            <v-checkbox v-model='selectedPolicyTypesIds' :value='type.id' color='primary'
                        :label='type.attributes.name' />
          </div>
          <div class='flex-shrink-1 text-end'
               v-if='selectedPolicyTypesIds.indexOf(type.id) !== -1 && availableTemplates[type.id]'>
            <v-select
              style='width:300px; margin-top:12px;'
              :items='availableTemplates[type.id]'
              outlined
              dense
              clearable
              hide-details
              :item-value='item => item.value'
              :item-text='item => item.text'
              placeholder='Select A Template'
              v-model='selectedPolicyTypeTemplates[type.id]'
              @change="$emit('selectedTemplates', selectedPolicyTypeTemplates)"
            />
          </div>
        </div>
      </div>
      <div style='text-align: center'>
        <v-btn color='primary' :disabled='selectedPolicyTypes.length === 0' @click='submit'>
          Confirm Selection
        </v-btn>
      </div>
    </template>
  </div>
</template>

<style lang='scss' scoped>
td {
  vertical-align: middle !important;
}

.policy-types {
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 400px;
  overflow: auto;

  .v-list-item--link:before {
    background-color: white;
  }
}
</style>

<script>
export default {
  computed: {
    displayedPolicyTypes() {
      let types = []

      _.forEach(this.policyTypes, (policyType) => {
        if (this.policyTypesCategory === 0 && policyType.attributes.category === 'personal') {
          types.push(policyType)
        }
        if (this.policyTypesCategory === 1 && policyType.attributes.category === 'commercial') {
          types.push(policyType)
        }
      })

      return types
    },

    selectedPolicyTypes() {
      let types = []

      _.forEach(this.policyTypes, (policyType) => {
        if (this.selectedPolicyTypesIds.indexOf(policyType.id) !== -1) {
          types.push(policyType)
        }
      })

      return types
    },

    availableTemplates() {
      let tempTemplates = {}

      _.forEach(this.templates, (template) => {
        if (typeof tempTemplates[template.related.policy_type.data.id] === 'undefined') {
          this.$set(tempTemplates, template.related.policy_type.data.id, [])
        }

        tempTemplates[template.related.policy_type.data.id].push({
          value: template.id,
          text: template.attributes.title,
        })
      })

      return tempTemplates
    },
  },

  watch: {
    policyTypesCategory() {
      this.selectedPolicyTypesIds = []
    },
  },

  methods: {
    loadPolicyTypes() {
      this.$api.policyTypes.index({ 'rater': true })
        .then(({ data }) => {
          this.policyTypes = data
          this.loading.policyTypes = false
        })
    },
    loadTemplates() {
      this.$api.raterTemplates.index()
        .then(({ data }) => {
          this.templates = data


          this.$nextTick(() => {
            this.loading.templates = false
          })
        })
    },
    submit() {
      this.$emit('submitted', this.selectedPolicyTypes)
    },
  },

  beforeMount() {
    this.loadPolicyTypes()
    this.loadTemplates()
  },

  data() {
    return {
      policyTypes: null,
      selectedPolicyTypesIds: [],
      policyTypesCategory: 0,
      templates: [],
      selectedPolicyTypeTemplates: {},
      loading: {
        templates: true,
        policyTypes: true,
      },
    }
  },
}
</script>
