<template>
  <div>
    <v-form ref='form' v-model='valid' lazy-validation>
      <v-container style='padding:0'>
        <h4 class='form-section-header'>Contact Details</h4>
        <div class='d-flex mb-5'>
          <v-text-field
            dense
            v-model='first_name'
            label='First Name *'
            hide-details='auto'
            :rules='[requiredRule]'
            :error-messages="getFieldErrors('first_name')"
            :error="fieldHasErrors('first_name')"
            outlined
            required
            style='width:200px;margin-right:5px;'
          />
          <v-text-field
            dense
            v-model='middle_name'
            label='Middle Name'
            hide-details='auto'
            :rules='[]'
            outlined
            style='width:200px; margin-left: 5px;margin-right:5px;'
          />
          <v-text-field
            dense
            v-model='last_name'
            label='Last Name*'
            hide-details='auto'
            :rules='[requiredRule]'
            :error-messages="getFieldErrors('last_name')"
            :error="fieldHasErrors('last_name')"
            outlined
            required
            style='width:200px; margin-left: 5px;margin-right:5px;'
          />

          <v-text-field
            dense
            v-model='suffix'
            label='Suffix'
            hide-details='auto'
            :rules='[]'
            :error-messages="getFieldErrors('suffix')"
            :error="fieldHasErrors('suffix')"
            outlined
            style='width:104px;margin-left:5px;'
          />
        </div>

        <div class='d-flex mb-5'>
          <v-text-field
            v-model='phone_number'
            label='Phone Number'
            :rules='[phoneRule]'
            outlined
            hide-details='auto'
            :error-messages="getFieldErrors('phone_numbers.0.phone_number')"
            :error="fieldHasErrors('phone_numbers.0.phone_number')"
            dense
            style='margin-right:5px;'
          />
          <v-text-field
            v-model='email'
            label='E-mail *'
            hide-details='auto'
            :rules='[requiredRule, emailRule]'
            :error-messages="getFieldErrors('email_addresses.0.email')"
            :error="fieldHasErrors('email_addresses.0.email')"
            outlined
            dense
            style='margin-left: 5px;'
            required
          />
        </div>

        <div class='mb-5'>
          <v-checkbox
            label='I have permission to text this person/company.'
            hide-details
            :disabled='!phone_number || !phone_number.length'
            v-model='permission_to_contact'
            dense></v-checkbox>
        </div>

        <div class='mb-5'>
          <autocomplete-address
            v-model='address'
            placeholder='Search by address'
            style='width:100%'
            v-if='addressMethod === "autocomplete"'
          />
        </div>
        <div class='' v-if='addressMethod === "autocomplete"'>
          <v-btn color='primary' class='pl-1' plain @click.prevent='swapAddressType'>Enter Address
            Manually
          </v-btn>
        </div>


        <div v-if='addressMethod === "manual"' class='mb-5'>
          <div class='d-flex mb-5'>
            <v-text-field
              dense
              v-model='street'
              label='Address Line 1'
              hide-details='auto'
              :rules='[]'
              :error-messages="getFieldErrors('addresses.0.street')"
              :error="fieldHasErrors('addresses.0.street')"
              outlined
              style='margin-right:5px;'
            />
            <v-text-field
              dense
              v-model='street2'
              label='Address Line 2'
              hide-details='auto'
              :rules='[]'
              :error-messages="getFieldErrors('addresses.0.extra')"
              :error="fieldHasErrors('addresses.0.extra')"
              outlined
              style='max-width:200px;margin-left:5px;'
            />
          </div>
          <div class='d-flex'>
            <v-text-field
              dense
              v-model='city'
              label='City'
              hide-details='auto'
              :rules='[]'
              :error-messages="getFieldErrors('addresses.0.city')"
              :error="fieldHasErrors('addresses.0.city')"
              outlined
            />

            <v-select
              dense
              v-model='state'
              label='State'
              hide-details='auto'
              :rules='[]'
              :error-messages="getFieldErrors('addresses.0.state')"
              :error="fieldHasErrors('addresses.0.state')"
              outlined
              clearable
              style='width:200px; margin-left: 5px;margin-right:5px;'
              :items='enums.states'
              item-value='short'
              item-text='name'
            />

            <v-text-field
              dense
              v-model='zip_code'
              label='Zip Code'
              hide-details='auto'
              :rules='[]'
              :error-messages="getFieldErrors('addresses.0.zip_code')"
              :error="fieldHasErrors('addresses.0.zip_code')"
              outlined
              style='width:104px;margin-left:5px;'
            />
          </div>
        </div>

        <div class='' v-if='addressMethod === "manual"'>
          <v-btn color='primary' class='pl-1' plain @click.prevent='swapAddressType'>Use Auto
            Suggest
          </v-btn>
        </div>

        <hr class='form-divider' />

        <h4 class='form-section-header'>Additional Details</h4>

        <div class='d-flex mb-5'>
          <v-select
            dense
            v-model='user_id'
            label='Assign Owner'
            hide-details='auto'
            :rules='[]'
            :error-messages="getFieldErrors('user_id')"
            :error="fieldHasErrors('user_id')"
            outlined
            style='width:200px; margin-right:5px;'
            :items='users'
            item-value='id'
            :item-text='user => user.attributes.name'
            :loading='usersLoaded === false'
          />


          <v-text-field
            dense
            v-model='driver_license_number'
            label="Driver's License #"
            hide-details='auto'
            :rules='[]'
            :error-messages="getFieldErrors('driver_license_number')"
            :error="fieldHasErrors('driver_license_number')"
            outlined
            style='width:250px;margin-left:5px;margin-right:5px;'
          />


          <v-select
            dense
            v-model='driver_license_state'
            label="Driver's License State"
            hide-details='auto'
            :rules='[]'
            clearable
            :error-messages="getFieldErrors('driver_license_state')"
            :error="fieldHasErrors('driver_license_state')"
            outlined
            style='width:200px; margin-left: 5px;'
            :items='enums.states'
            item-value='short'
            item-text='name'
          />

        </div>

        <div class='d-flex mb-5'>
          <v-menu
            v-model='dateOfBirthPicker'
            :close-on-content-click='false'
            :nudge-right='40'
            transition='scale-transition'
            offset-y
            min-width='auto'
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-text-field
                v-model='birthdate'
                outlined
                dense
                hide-details='auto'
                label='Date of Birth'
                :append-icon='icons.mdiCalendar'
                :error-messages="getFieldErrors('birthdate')"
                :error="fieldHasErrors('birthdate')"
                readonly
                v-bind='attrs'
                v-on='on'
                style='width:250px;margin-right:5px;'
              ></v-text-field>
            </template>
            <v-date-picker
              v-model='birthdate'
              @input='dateOfBirthPicker = false'
            ></v-date-picker>
          </v-menu>

          <v-select
            dense
            v-model='marital_status'
            label='Marital Status'
            hide-details='auto'
            clearable
            :rules='[]'
            :error-messages="getFieldErrors('marital_status')"
            :error="fieldHasErrors('marital_status')"
            outlined
            style='width:200px; margin-left: 5px;margin-right:5px;'
            :items='maritalStatuses'
          />

          <v-select
            dense
            v-model='gender'
            label='Gender'
            hide-details='auto'
            clearable
            :rules='[]'
            :error-messages="getFieldErrors('gender')"
            :error="fieldHasErrors('gender')"
            outlined
            style='width:200px; margin-left: 5px;'
            :items='genders'
          />
          <!--<v-select
            dense
            v-model='own_or_rent'
            label='Own or Rent'
            hide-details='auto'
            clearable
            :rules='[]'
            :error-messages="getFieldErrors('own_or_rent')"
            :error="fieldHasErrors('own_or_rent')"
            outlined
            style='width:200px; margin-left: 5px;'
            :items="[{text: 'Own', value: 'own'}, {text: 'Rent', value: 'rent'}]"
          />-->
        </div>

        <div class='d-flex mb-5'>
          <v-select
            dense
            v-model='education'
            label='Education'
            hide-details='auto'
            clearable
            :rules='[]'
            :error-messages="getFieldErrors('education')"
            :error="fieldHasErrors('education')"
            outlined
            style='width:250px;margin-right:5px;'
            :items='educations'
            item-value='value'
            item-text='text'
          />

          <v-select
            dense
            v-model='industry'
            label='Industry'
            hide-details='auto'
            clearable
            :rules='[]'
            :error-messages="getFieldErrors('industry')"
            :error="fieldHasErrors('industry')"
            outlined
            style='width:200px; margin-left: 5px;margin-right:5px;'
            :items='industries'
            item-value='value'
            item-text='text'
          />

          <v-select
            dense
            v-model='occupation'
            label='Occupation'
            clearable
            hide-details='auto'
            :rules='[]'
            :error-messages="getFieldErrors('occupation')"
            :error="fieldHasErrors('occupation')"
            outlined
            style='width:200px; margin-left: 5px;'
            :items='availableOccupations'
            item-value='value'
            item-text='text'
            :disabled='industry === null'
          />
        </div>
      </v-container>

      <div style='text-align: center'>
        <v-btn
          :disabled='isSubmitting'
          color='primary'
          @click='validate'>
          Create New Contact
        </v-btn>
      </div>

    </v-form>
  </div>
</template>

<script>
import AutocompleteAddress from '@/components/AutocompleteAddress'
import { mapActions, mapGetters } from 'vuex'

import { mdiCalendar } from '@mdi/js'
import HasFormErrors from '@/config/mixins/HasFormErrors'

export default {
  mixins: [HasFormErrors],
  components: { AutocompleteAddress },
  computed: {
    ...mapGetters({
      enums: 'enums/getEnums',
      users: 'users/getUsers',
      usersLoaded: 'users/getUsersLoaded',
      currentUser: 'getCurrentUser',
    }),
    requiredRule() {
      return v => !!v || 'Required'
    },

    emailRule() {
      return v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    },

    phoneRule() {
      return function(value) {
        if (value) {
          let number = ''
          for (let i = 0; i < value.length; i++) {
            if (/^\d$/.test(value[i])) {
              number += value[i]
            }
          }

          if (number.length !== 10) {
            //commenting out for now, if has country code would fail or if short code
            // return 'Phone number is invalid'
          }
        }

        return true
      }
    },
    genders() {
      return _.map(this.enums.genders, (index, value) => {
        return {
          text: index,
          value: value,
        }
      })
    },
    maritalStatuses() {
      return _.map(this.enums.marital_statuses, (index, value) => {
        return {
          text: index,
          value: value,
        }
      })
    },
    educations() {
      return _.map(this.enums.educations, (index, value) => {
        return {
          text: index,
          value: value,
        }
      })
    },
    industries() {
      let industries = _.map(this.enums.industries, (index, value) => {
        return {
          text: index.name,
          value: index.id,
        }
      })

      // industries.unshift({
      //   text: 'Select Industry',
      //   value: null,
      // })

      return industries
    },
    availableOccupations() {
      if (this.industry) {
        let index = _.findIndex(this.enums.industries, (industry) => {
          return industry.id === this.industry
        })

        let occupations = this.enums.industries[index].occupations

        occupations = _.map(occupations, (index, value) => {
          return {
            text: index,
            value: value,
          }
        })
        //
        // occupations.unshift({
        //   text: 'Select Occupation',
        //   value: null,
        // })

        return occupations
      }

      return []
    },
  },
  methods: {
    ...mapActions({ loadUsers: 'users/loadUsers' }),
    validate() {
      if (this.$refs.form.validate()) {
        this.isSubmitting = true

        this.$api.contacts.create({
          first_name: this.first_name,
          last_name: this.last_name,
          middle_name: this.middle_name,
          suffix: this.suffix,
          email: this.email,
          phone_number: this.phone_number,
          permission_to_contact: this.permission_to_contact,
          address: (() => {
            if (this.addressMethod === 'autocomplete') {
              if (this.address !== null) {
                this.address.ownership = this.own_or_rent
              }

              return this.address
            } else {
              return {
                street: this.street,
                city: this.city,
                state: this.state,
                zip_code: this.zip_code,
                ownership: this.own_or_rent,
                details: this.street2,
              }
            }
          })(),
          gender: this.gender,
          education: this.education,

          driver_license_number: this.driver_license_number,
          driver_license_state: this.driver_license_state,

          industry: this.industry,
          occupation: this.occupation,
          marital_status: this.marital_status,
          birthdate: this.birthdate,
          user_id: this.user_id,
        }).then((response) => {
          this.$emit('submitted', response.data)
        }).catch(validationException => {
          this.setFormException(validationException)
        }).finally(() => {
          this.isSubmitting = false
        })
      }
    },
    swapAddressType() {
      this.clearFormException()
      this.addressMethod = this.addressMethod === 'autocomplete' ? 'manual' : 'autocomplete'
    },
  },

  watch: {
    industry() {
      if (this.industry === null) {
        this.occupation = null
      } else if (this.availableOccupations.length <= 2) {
        this.occupation = this.availableOccupations[1].value
      }
    },
    usersLoaded(value) {
      if (value) {
        //select the current user

        this.user_id = this.currentUser.id
      }
    },
    addressMethod(value){
      if (value === 'autocomplete'){
        this.address = null;
      }
    },
    phone_number(value){
      if(!value || !value.length){
        this.permission_to_contact = false;
      }
    },
    address: {
      deep: true,
      handler(value) {
        if (value !== null) {
          this.street = value.street
          this.street2 = value.details
          this.city = value.city
          this.state = value.state
          this.zip_code = value.zip_code

          this.addressMethod = 'manual';
        }
      },
    },
  },

  data() {
    return {
      icons: {
        mdiCalendar,
      },

      valid: true,
      isSubmitting: false,

      first_name: null,
      middle_name: null,
      last_name: null,
      suffix: null,
      email: null,
      phone_number: null,
      permission_to_contact: false,
      user_id: null,

      addressMethod: 'autocomplete',
      address: null,

      street: null,
      street2: null,
      city: null,
      state: null,
      zip_code: null,

      driver_license_number: null,
      driver_license_state: null,
      own_or_rent: null,
      birthdate: null,

      marital_status: null,
      gender: null,
      education: null,
      industry: null,
      occupation: null,

      dateOfBirthPicker: false,
    }
  },
  mounted() {
    this.loadUsers()
  },
}
</script>

<style lang='scss' scoped>
.form-divider {
  box-sizing: border-box;
  width: 752px;
  height: 1px !important;
  border: 1px solid rgba(58, 53, 65, 0.12) !important;
  flex: none;
  order: 2;
  flex-grow: 0;
  z-index: 2;
  margin-top: 25px;
  margin-bottom: 25px;
}

.form-section-header {
  font-weight: bold;
  font-size: 14px;
  color: #3A3541;
  margin-bottom: 20px;
}
</style>
