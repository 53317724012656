var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.applications === false)?[_c('v-alert',{attrs:{"border":"top","color":"red lighten-2","dark":""}},[_vm._v(" An alert occurred. Please try again. ")])]:[_c('h3',{staticClass:"page-header"},[_vm._v("Rater Applications")]),(_vm.applications !== null)?[_c('div',{staticStyle:{"display":"flex","margin-bottom":"20px","align-items":"center"}},[_c('div',{staticStyle:{"flex":"1"}},[_c('strong',[_vm._v(_vm._s(_vm.total))]),_vm._v(" Rater applications "),(_vm.sso)?[_c('form',{staticStyle:{"display":"inline","margin-left":"10px"},attrs:{"method":"post","action":_vm.sso.url,"target":"_blank"}},[_c('input',{attrs:{"type":"hidden","name":"Authorization"},domProps:{"value":_vm.sso.token}}),_c('v-btn',{attrs:{"small":"","color":"primary","type":"submit"}},[_vm._v("Connect to Dashboard")])],1)]:_vm._e()],2),_c('div',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.startApplication}},[_vm._v("Start New Application")])],1)])]:_vm._e(),(_vm.newApplicationFormIsShown)?_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.newApplicationFormIsShown),callback:function ($$v) {_vm.newApplicationFormIsShown=$$v},expression:"newApplicationFormIsShown"}},[_c('v-card',[_c('v-card-text',[(_vm.newApplicationFormIsShown)?_c('new-application-form',{on:{"submitted":_vm.onNewApplication}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.newApplicationFormIsShown = false}}},[_vm._v(" Close ")])],1)],1)],1):_vm._e(),_c('v-snackbar',{attrs:{"timeout":4000,"centered":"","multi-line":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [(_vm.latestApplication)?_c('v-btn',{attrs:{"target":"_blank","href":_vm.latestApplication.attributes.link,"color":"primary","text":""},on:{"click":function($event){_vm.updateSuccessful = false}}},[_vm._v(" Fill application ")]):_vm._e()]}}]),model:{value:(_vm.newApplicationSubmitted),callback:function ($$v) {_vm.newApplicationSubmitted=$$v},expression:"newApplicationSubmitted"}},[_c('strong',[_vm._v("Application created!")]),_c('div',{staticStyle:{"margin-top":"4px"}},[_vm._v("Fill missing details to generate quotes.")])]),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"loading":_vm.applications === null,"hide-default-footer":true,"disable-pagination":true},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"line-height":"1.2"}},[_c('div',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.name))]),_c('div',[_c('small',[_vm._v(_vm._s(item.email))])])])]}},{key:"item.lob",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(item.lob))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'pending')?[_c('v-chip',{attrs:{"label":"","color":"info"}},[_vm._v("In progress")])]:[_c('v-chip',{attrs:{"label":"","color":"success"}},[_c('strong',{staticStyle:{"margin-right":"5px"}},[_vm._v(_vm._s(item.quotes_count))]),_vm._v(" "+_vm._s(item.quotes_count > 1 ? 'Quotes' : 'Quote')+" received ")])]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":item.link}},[_vm._v(" "+_vm._s(item.status === 'pending' ? 'Continue' : 'View'))])]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }