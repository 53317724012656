<template>
  <div>
    <v-stepper v-model='step' :flat='true'>
      <v-stepper-header style='box-shadow: 0 2px 10px 0 rgb(94 86 105 / 10%)'>
        <v-stepper-step step='1' :complete='selectedContact !== null'>
          Contact
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step='2' :complete='selectedPolicyTypes.length > 0'>
          Line of Business
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step='3' :complete='details !== null'>
          Details
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- Contact Form -->
        <v-stepper-content step='1'>
          <contact-step @contact-selected='selectContact'></contact-step>
        </v-stepper-content>

        <!-- LOB list -->
        <v-stepper-content step='2'>
          <policy-type-step @submitted='selectPolicyTypes'
                            @selectedTemplates='selectTemplates'></policy-type-step>
        </v-stepper-content>

        <!-- Details -->
        <v-stepper-content step='3'>
          <details-step
            v-if='step === 3'
            :contact='selectedContact'
            :policyTypes='selectedPolicyTypes'
            @submitted='setDetails'>
          </details-step>
        </v-stepper-content>

        <v-stepper-content step='4'>
          <div class='step-label' style='margin-bottom: 15px'>
            Do you want to create this application?
          </div>

          <div v-if='selectedContact' class='application-info'>
            <strong>Contact</strong>
            <div class='application-value'>{{ selectedContact.attributes.name }}</div>
          </div>

          <div v-if='selectedPolicyTypes.length > 0' class='application-info'>
            <strong>Line of Business</strong>
            <div class='application-value' style='margin-top: 4px'>
              <div class='policy-type-tag' v-for='type in selectedPolicyTypes'>
                {{ type.attributes.name }}
              </div>
            </div>
          </div>

          <template v-if='details'>
            <div v-if='details.autos' class='application-info'>
              <strong>Autos selected</strong>
              <div class='application-value'>{{ details.autos.length }}</div>
            </div>

            <div v-if='details.properties' class='application-info'>
              <strong>Properties selected</strong>
              <div class='application-value'>{{ details.properties.length }}</div>
            </div>
          </template>

          <div style='margin-top:15px; text-align: center'>
            <v-btn color='primary' :disabled='isSubmitting' @click='submitApplication'>
              {{ isSubmitting ? 'Please wait ...' : 'Start Quote' }}
            </v-btn>
          </div>

        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<style lang='scss' scoped>
::v-deep .step-label {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'Roboto Slab', serif;
  color: #422F2F;
}

.application-info {
  border: solid 1px #efefef;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  min-height: 55px;

  .strong {
    color: #422F2F;
  }

  .application-value {
    flex: 1;
    text-align: right;
  }

  .policy-type-tag {
    margin-left: 5px;
    display: inline-block;
    border: solid 1px #e0e0e0;
    padding: 3px 10px;
    border-radius: 5px;
  }
}

</style>

<script>
import ContactStep from './ContactStep/index'
import PolicyTypeStep from './PolicyTypeStep/index'
import DetailsStep from './DetailsStep/index'
import _ from 'lodash'

export default {
  components: {
    ContactStep,
    PolicyTypeStep,
    DetailsStep,
  },

  methods: {
    selectContact(contact) {
      this.selectedContact = contact
      this.step = 2
    },

    selectTemplates(templates) {
      this.selectedTemplates = _.filter(Object.values(templates), (template) => {
        return template !== null
      })
    },

    selectPolicyTypes(policyTypes) {
      this.selectedPolicyTypes = policyTypes
      this.step = 3
    },

    setDetails(details) {
      this.details = details
      this.step = 4
    },

    submitApplication() {
      this.isSubmitting = true

      let finalDetails = {}
      _.forEach(this.details, (objects, key) => {
        finalDetails[key] = []
        _.forEach(objects, (object) => {
          finalDetails[key].push(object.id)
        })
      })

      let finalPolicyTypes = []
      _.forEach(this.selectedPolicyTypes, (policyType) => {
        finalPolicyTypes.push(policyType.id)
      })

      this.$api.rater.createApplication({
        contact: this.selectedContact.id,
        policy_types: finalPolicyTypes,
        templates: this.selectedTemplates,
        details: finalDetails,
      })
        .then((response) => {
          this.isSubmitting = false

          this.$emit('submitted', response.data)
        })
    },
  },

  data() {
    return {
      step: 1,
      selectedContact: null,
      selectedPolicyTypes: [],
      selectedTemplates: [],
      details: null,
      isSubmitting: false,
    }
  },
}
</script>
